<template>
  <table class="table table-striped">
    <thead>
    <tr>
      <th class="col-id">Id</th>
      <th class="col-name">Name</th>
      <th class="col-namespace">Namespace</th>
      <th class="col-class">Class</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="message in messages" :key="message.id" :class="{'table-warning': !message.confident}">
      <td class="text-monospace">{{ message.id }}</td>
      <td>{{ message.name }}</td>
      <td class="text-monospace">{{ message.asNamespace }}</td>
      <td class="text-monospace">{{ message.asClass }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "MessageTable",
  props: {
    messages: Array
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 992px) {
  .col-id {
    width: 5%;
  }

  .col-name {
    width: 35%;
  }

  .col-namespace {
    width: 30%;
  }

  .col-class {
    width: 30%;
  }
}
</style>
