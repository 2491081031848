<template>
  <div>
    <font-awesome-icon icon="globe" v-if="variant === 'Flash'"/>
    <font-awesome-icon :icon="['fab', 'windows']" v-if="variant === 'FlashWindows'"/>
    <font-awesome-icon :icon="['fab', 'apple']" v-if="variant === 'FlashOSX'"/>
    <font-awesome-icon icon="mobile-alt" v-if="variant === 'Mobile'"/>
    <font-awesome-icon :icon="['fab', 'windows']" v-if="variant === 'ShockwaveWindows'"/>
    <font-awesome-icon :icon="['fab', 'apple']" v-if="variant === 'ShockwaveOSX'"/>
  </div>
</template>

<script>
export default {
  name: "VariantIcon",
  props: {
    variant: String
  }
}
</script>

<style scoped>
div {
  display: inline;
}
</style>
