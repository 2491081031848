<template>
  <div>
    <div v-if="!loaded">
      <div class="row">
        <div class="col-md-12">
          <h3>Loading release <font-awesome-icon icon="spinner" spin /> </h3>
          <p>Please wait..</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-12">
          <h3><VariantIcon :variant="release.variant"/> <VariantName :variant="release.variant"/></h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-release-info">
              <tbody>
              <tr>
                <td><strong>Version</strong></td>
                <td>{{ release.version }}</td>
              </tr>
              <tr>
                <td><strong>Upload type</strong></td>
                <td>{{ release.uploadType }}</td>
              </tr>
              <tr>
                <td><strong>Released at</strong></td>
                <td>{{ dateRelease }}</td>
              </tr>
              <tr>
                <td><strong>Messages checked at</strong></td>
                <td>{{ dateMessagesChecked }}</td>
              </tr>
              <tr>
                <td><strong>Incoming messages</strong></td>
                <td>{{ messages.messages.incoming.length }}</td>
              </tr>
              <tr>
                <td><strong>Outgoing messages</strong></td>
                <td>{{ messages.messages.outgoing.length }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>Files</h4>
          <div class="alert alert-warning" role="alert" v-if="release.cracked">
            <strong>Warning!</strong> The files uploaded for this release are not original, keep in mind when downloading.
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th style="width: 85%;">File</th>
                <th style="min-width: 100px;">Size</th>
                <th style="min-width: 150px;"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="file in release.files" :key="file.id">
                <td class="align-middle">{{ file.name }}</td>
                <td class="align-middle">{{ file.size | prettyBytes }}</td>
                <td>
                  <a type="button" class="btn btn-primary btn-block" :href="file.url" target="_blank">Download</a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>Messages</h4>
          <div v-if="hasAnyMessages">
            <nav class="nav nav-tabs nav-justified">
              <a class="nav-item nav-link" :class="{active: selectedDirection === 'incoming'}" @click="selectedDirection = 'incoming'">
                <font-awesome-icon icon="arrow-circle-down"/> Incoming
              </a>
              <a class="nav-item nav-link" :class="{active: selectedDirection === 'outgoing'}" @click="selectedDirection = 'outgoing'">
                <font-awesome-icon icon="arrow-circle-up"/> Outgoing
              </a>
            </nav>
            <div class="table-responsive" v-if="isShockwave">
              <MessageTableShockwave :messages="messages.messages.incoming" v-if="selectedDirection === 'incoming'"/>
              <MessageTableShockwave :messages="messages.messages.outgoing" v-else/>
            </div>
            <div class="table-responsive" v-else>
              <MessageTable :messages="messages.messages.incoming" v-if="selectedDirection === 'incoming'"/>
              <MessageTable :messages="messages.messages.outgoing" v-else/>
            </div>
          </div>
          <div v-else class="alert alert-secondary" role="alert">
            No messages have been found for this release.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import moment from "moment/dist/moment"
import MessageTable from "@/components/MessageTable"
import MessageTableShockwave from "@/components/MessageTableShockwave"
import VariantName from "@/components/VariantName"
import VariantIcon from "@/components/VariantIcon"

export default {
  name: 'Release',
  components: {VariantName, VariantIcon, MessageTable, MessageTableShockwave},
  data: function () {
    return {
      selectedDirection: 'incoming',
      release: null,
      messages: null
    }
  },
  computed: {
    dateRelease() {
      return moment(this.release.release).format('MM/DD/YYYY hh:mm')
    },
    dateMessagesChecked() {
      return moment(this.messages.lastCheckedAt).format('MM/DD/YYYY hh:mm')
    },
    loaded() {
      return this.release !== null && this.messages !== null;
    },
    hasAnyMessages() {
      return (this.messages.messages.incoming && this.messages.messages.incoming.length > 0) ||
             (this.messages.messages.outgoing && this.messages.messages.outgoing.length > 0)
    },
    isShockwave() {
      return this.release.variant === 'ShockwaveWindows' || this.release.variant === 'ShockwaveOSX'
    }
  },
  methods: {
    fetchRelease() {
      axios.get(`${process.env.VUE_APP_API_URL}/releases/${this.$route.params.variant}/${this.$route.params.version}`).then(response => {
        this.release = response.data
      })
    },
    fetchReleaseMessage() {
      axios.get(`${process.env.VUE_APP_API_URL}/releases/${this.$route.params.variant}/${this.$route.params.version}/messages`).then(response => {
        this.messages = response.data
      })
    },
    progress(value, max) {
      return (100 / max * value).toFixed(2)
    },
  },
  mounted() {
    this.fetchRelease()
    this.fetchReleaseMessage()
  }
}
</script>

<style lang="scss" scoped>
.nav-item {
  cursor: pointer;
}

.table-release-info {
  tr td:first-child {
    width: 25%;
  }
}
</style>
