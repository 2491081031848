<template>
  <div>
    <span v-if="variant === 'Flash'">Web</span>
    <span v-if="variant === 'FlashWindows'">Habbo Air</span>
    <span v-if="variant === 'FlashOSX'">Habbo Air</span>
    <span v-if="variant === 'Mobile'">Habbo Air</span>
    <span v-if="variant === 'ShockwaveWindows'">Habbo Hotel: Origins</span>
    <span v-if="variant === 'ShockwaveOSX'">Habbo Hotel: Origins</span>
  </div>
</template>

<script>
export default {
  name: "VariantIcon",
  props: {
    variant: String
  }
}
</script>

<style scoped>
div {
  display: inline;
}
</style>
